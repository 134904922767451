import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { Employee } from '../../../modules/cs/common/components/employee';
import { Ship } from '../../../modules/cs/common/components/ship';
import { Row, Col } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const CSShadowPalaceF5Page: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Shadow Palace - Floor 5</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/shadow_floor_5.png"
            alt="Shadow Palace"
          />
        </div>
        <div className="page-details">
          <h1>Shadow Palace - Floor 5</h1>
          <h2>Guide for the fifth floor of Shadow Palace.</h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="MVP employees" />
        <div className="employee-container">
          <Employee
            slug="awakened-yuna-springfield"
            mode="icon"
            enablePopover
          />
          <Employee slug="awakened-seo-yoon" mode="icon" enablePopover />
        </div>
        <p>
          If you have Awakened Yuna or Awakened Seo Yoon simply adding them to
          your team will help you a lot.
        </p>
        <div className="employee-container">
          <Employee slug="edel-meitner" mode="icon" enablePopover />
          <Employee slug="kyle-wong" mode="icon" enablePopover />
        </div>
        <p>
          Similar to Floor 4, enemies' Special skills can wipe your team, having
          hit absorbers is necessary - both Kyle's walls and Edel's summons fill
          that role really well.
        </p>
        <div className="employee-container">
          <Employee slug="shin-jia" mode="icon" enablePopover />
        </div>
        <p>
          Shin Jia fulfills three roles here - buffer, hit absorber and CC bot.
        </p>
        <div className="employee-container">
          <Employee slug="serapel" mode="icon" enablePopover />
          <Employee slug="lee-yumi" mode="icon" enablePopover />
        </div>
        <p>
          You need a good tank that can also support the team in some way. Lee
          Yumi is the better choice though as she can resist being knocked back
          constantly.
        </p>
        <div className="employee-container">
          <Employee slug="choi-jihoon" mode="icon" enablePopover />
          <Employee slug="john-mason" mode="icon" enablePopover />
        </div>
        <p>
          Their silence will be really useful here, but Choi Jihoon isn't
          available in the Global server yet.
        </p>
        <div className="employee-container">
          <Employee slug="xiao-lin" mode="icon" enablePopover />
          <Employee slug="kestrel-xiao-lin" mode="icon" enablePopover />
        </div>
        <p>
          You want to end the battles as fast as possible and the best damage
          dealer in the game will help you with that. She's especially great
          here because all enemies are Strikers.
        </p>
        <div className="employee-container">
          <Ship unitId="26026" mode="icon" enablePopover />
        </div>
        <p>New Ohio's silence makes clearing this floor a lot easier.</p>
        <SectionHeader title="Enemies" />
        <StaticImage
          src="../../../images/counterside/shadow/AB_INVEN_ICON_NKM_SHADOW_C_HILDE_ADMIN.png"
          alt="Shadow Enemy"
        />
        <p>
          Evil Hilde on top of her normal buffs will also provide allies with
          debuff resistance.{' '}
        </p>
        <StaticImage
          src="../../../images/counterside/shadow/AB_INVEN_ICON_NKM_SHADOW_C_ADMIN_NA_YUBIN.png"
          alt="Shadow Enemy"
        />
        <p>
          Evil Na Yubin's kit is pretty similar to his normal version, only made
          stronger.
        </p>
        <StaticImage
          src="../../../images/counterside/shadow/AB_INVEN_ICON_NKM_SHADOW_C_ADMIN_LEE_SUYEON.png"
          alt="Shadow Enemy"
        />
        <p>
          Evil LSY's kit is pretty similar to her normal version, only made
          stronger.
        </p>
        <h4>Ship</h4>
        <p>
          Similar to the characters, they didn't change the ship's skills, only
          made them more annoying.
        </p>
        <SectionHeader title="Restrictions" />
        <ul>
          <li>Room 1 - Strikers are banned</li>
          <li>Room 2 - Snipers are banned</li>
          <li>Room 3 - Supporters are banned</li>
          <li>Room 4 - Max 25 deployment cost</li>
          <li>Room 5 - Max 25 deployment cost and Awakened units are banned</li>
        </ul>
        <SectionHeader title="Important" />
        <p>
          This floor can be cleared with multiple teams and you don't have to
          follow the guide 100%. Adding A. Yuna or A. Seo Yoon to your team will
          make it a lot easier.
        </p>
        <SectionHeader title="Room 1" />
        <p>
          You're going to fight Evil Hilde here and the fight is pretty
          straightforward - just tank her and let Xiao Lin do the job.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="xiao-lin" mode="icon" enablePopover />
          <Employee slug="lee-yumi" mode="icon" enablePopover />
          <Employee slug="gaeun" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="administration-rifleman" mode="icon" enablePopover />
          <Employee slug="shin-jia" mode="icon" enablePopover />
          <Employee slug="eddie-fisher" mode="icon" enablePopover />
          <Employee slug="seo-yoon" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 2" />
        <p>
          Well, Xiao Lin won't help you in this room, so it's a lot harder than
          on the previous one.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="nanahara-chifuyu" mode="icon" enablePopover />
          <Employee slug="gaeun" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
          <Employee slug="ifrit" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="administration-rifleman" mode="icon" enablePopover />
          <Employee slug="shin-jia" mode="icon" enablePopover />
          <Employee slug="hilde" mode="icon" enablePopover />
          <Employee slug="seo-yoon" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 3" />
        <p>
          Xiao Lin is back on the menu, so use her! Healers can't be used in
          this room though, so you will have to overwhelm the enemies.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="xiao-lin" mode="icon" enablePopover />
          <Employee slug="lycoris" mode="icon" enablePopover />
          <Employee slug="kyle-wong" mode="icon" enablePopover />
          <Employee slug="nanahara-chifuyu" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="hilde" mode="icon" enablePopover />
          <Employee slug="shin-jia" mode="icon" enablePopover />
          <Employee slug="seo-yoon" mode="icon" enablePopover />
          <Employee slug="eddie-fisher" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 4" />
        <p>
          Well, things are getting harder with 2 shadows at the same time and
          this and the last room use the same tactics, but are more of a gear
          check.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="nanahara-chifuyu" mode="icon" enablePopover />
          <Employee slug="gaeun" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
          <Employee slug="xiao-lin" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="ifrit" mode="icon" enablePopover />
          <Employee slug="shin-jia" mode="icon" enablePopover />
          <Employee slug="hilde" mode="icon" enablePopover />
          <Employee slug="seo-yoon" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 5" />
        <p>Good luck.</p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="xiao-lin" mode="icon" enablePopover />
          <Employee slug="nanahara-chifuyu" mode="icon" enablePopover />
          <Employee slug="gaeun" mode="icon" enablePopover />
          <Employee slug="yang-harim" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="ifrit" mode="icon" enablePopover />
          <Employee slug="seo-yoon" mode="icon" enablePopover />
          <Employee slug="shin-jia" mode="icon" enablePopover />
          <Employee slug="hilde" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Video guide" />
        <p>
          The video guide was made for SEA when the floor released there, but
          most of the characters used there are already present in Global too.
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="2kZb8lEIcDo" />
          </Col>
        </Row>
        <p>
          Alternatively, you can check Fwuq - he already made videos for each
          floor. Linking the first room and you can find the rest rooms on his
          channel.
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="1vsScZVzmrM" />
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default CSShadowPalaceF5Page;

export const Head: React.FC = () => (
  <Seo
    title="Shadow Palace 5 guide | Counter Side | Prydwen Institute"
    description="Guide for the fifth floor of Shadow Palace."
  />
);
